import React from 'react'
import './style.scss'

const Overlay = () => {
  return (
    <div className="overlay">
    </div>
  )
}

export default Overlay;
