import React, { useEffect } from "react"
import { Link, navigate } from "gatsby"

import "./style.scss"
import { tabs } from "../NewHeader"
import WebpImage from "../WebpImage"

const SubMenu = ({
  show,
  setIsMenuOpened,
  onHide,
  data,
  activeTab,
  setActiveTab,
}) => {

  const handleOnMouseLeave = () => {
    if (document.querySelector(".sub-menu")) document.querySelector(".sub-menu").setAttribute("style", "transform: translate(0, -100%);")
    setTimeout(() => {
      setIsMenuOpened(false)
      setActiveTab(tabs.DEFAULT)
    }, 300)
  }

  useEffect(() => {
    if (show) {
      document.body.setAttribute("style", "overflow: hidden;")
      if (document.querySelector(".sub-menu")) document.querySelector(".sub-menu").setAttribute("style", "transform: translate(0, 0);")
    }

    return () => {
      document.body.removeAttribute("style")
    }
  }, [activeTab, show])

  if (!show || !activeTab) return null
  return (
    <div className="sub-menu-overlay">
      <div className="sub-menu" onMouseLeave={handleOnMouseLeave}>
        {
          activeTab == 'Contact Lenses' ?
          <ContactsLinks linksList={data['contactlensesv2']?.linksList} handleClick={onHide} /> :
          <>
            <GlassesLinks linksList={data[activeTab?.toLowerCase().replace(' ', '')]?.linksList} handleClick={onHide} />
            <GlassesFeatured featured={data[activeTab?.toLowerCase().replace(' ', '')]?.featured} />
          </>
        }
      </div>
    </div>
  )
}

const ContactsLinks = ({ linksList, handleClick }) => {
  return (
    <div className="links-grid">
      {linksList.map((lists) => {
          return(
            <div className="links-block">
              {
                lists.map(({ label, to, subLinks, description, underline }, index) => {
                  // { label, to, subLinks, description }
                  if (description)
                    return (
                      <div className="sub-links">
                        <h6 className={`sub-links-heading ${index == 0 && 'bold'} ${description ? "contacts" : ""}`}>
                        <Link to={to} onClick={handleClick}>
                          {label}
                          </Link>
                        </h6>
                        <div className="sub-links-container">
                          <span className="description">{description}</span>
                        </div>
                      </div>
                    )

                  return (
                    <Link to={to} onClick={handleClick} className={`desktop-link ${!to && 'no-opacity'} ${underline && 'underline'} ${index == 0 && 'bold'}`}>
                      {label}
                    </Link>
                  )
                })
              }
            </div>
          )
      })}
    </div>
  )
}


const GlassesLinks = ({ linksList, handleClick }) => {
  return (
    <div className="links-block">
      {React.Children.toArray(
        linksList.map(({ label, to, subLinks, description }) => {
          if (subLinks || description)
            return (
              <div className="sub-links">
                <h6 className={`sub-links-heading ${description ? "contacts" : ""}`}>
                <Link to={to} onClick={handleClick}>
                  {label}
                  </Link>
                </h6>
                <div className="sub-links-container">
                  {subLinks && subLinks.map(({ name, link }, index) => (
                    <Link
                      className="mini-link"
                      onClick={handleClick}
                      to={link}
                      key={index}
                    >
                      {name}
                    </Link>
                  ))}
                  <span className="description">{description}</span>
                </div>
              </div>
            )

          return (
            <Link to={to} onClick={handleClick} className="desktop-link">
              {label}
            </Link>
          )
        })
      )}
    </div>
  )
}

const GlassesFeatured = ({ featured }) => {
  return (
    <div className="featured-block">
      {featured?.map(({ image, title, price, link, action }, index) => (
        <div
          className="product"
          key={index}
          onClick={() => navigate(link)}
        >
          <div className="background-image">
            <WebpImage objectFit="contain" fileName={image} />
          </div>
          <div className="info-container">
            <div className="product-info">
              <h6 className="title">{title}</h6>
              <p className="price">{price}</p>
              {action && <p className="action">{action}</p>}
            </div>
          </div>
        </div>
      ))}
    </div>
  )
}

export default SubMenu
