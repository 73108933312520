import React, { useEffect, useRef } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faTimes } from "@fortawesome/pro-light-svg-icons"

import "./style.scss"

const CustomOffCanvas = ({ show, onHide, className, empty, children }) => {
  const overlayRef = useRef()

  useEffect(() => {
    if (typeof window !== "undefined") {
      const checkIfClickedOutside = e => {
        show &&
          overlayRef.current &&
          overlayRef.current.isEqualNode(e.target) &&
          onHide()
      }

      if (show) {
        document.body.setAttribute("style", "overflow: hidden;")
        document.querySelector('.custom-offcanvas').setAttribute("style", "transform: translate(0, 0);")
        document.addEventListener("mousedown", checkIfClickedOutside)
      }

      return () => {
        document.body.removeAttribute("style")
        document.removeEventListener("mousedown", checkIfClickedOutside)
      }
    }
  }, [show])

  if (!show) return null

  return (
    <div className="custom-offcanvas-overlay" ref={overlayRef}>
      <div
        className={`custom-offcanvas${className ? ` ${className}` : ""}`}
        data-empty={empty}
      >
        {children}
      </div>
    </div>
  )
}

const Header = ({ onHide, children }) => {
  return (
    <div className="custom-offcanvas-header">
      <div className="top-block">
        <button onClick={onHide} aria-label="close">
          <FontAwesomeIcon icon={faTimes} size="2x" aria-label="close"/>
        </button>
      </div>
      <div className="bottom-block">{children}</div>
    </div>
  )
}
const Body = ({ children }) => {
  return <div className="custom-offcanvas-body">{children}</div>
}
const Footer = ({ children }) => {
  return <div className="custom-offcanvas-footer">{children}</div>
}

CustomOffCanvas.Header = Header
CustomOffCanvas.Body = Body
CustomOffCanvas.Footer = Footer

export default CustomOffCanvas