import React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faAngleDown } from "@fortawesome/pro-regular-svg-icons"

import "./style.scss"

const PrimarySelect = ({ selectedValue, setValue, values }) => {
  return (
    <div className="primary-select-container">
      <select
        className="primary-select text h8 neutral black"
        value={selectedValue}
        onChange={setValue}
      >
        {values.map((item, index) => (
          <option
            className="text h8 neutral"
            key={index}
            value={item.value}
            onChange={setValue}
          >
            {item.label}
          </option>
        ))}
      </select>
      <FontAwesomeIcon icon={faAngleDown} className="arrow" />
    </div>
  )
}

export default PrimarySelect
