export const mainLinksList = [
  {
    label: "Contact Lenses",
    to: "/contact-lenses/hubble/",
  },
  {
    label: "Eyeglasses",
    to: "/glasses/",
  },
  {
    label: "Sunglasses",
    to: "/sunglasses/",
  },
  {
    label: "Eye Care Accessories",
    to: "/eye-care-accessories/",
  },
  {
    label: "Vision Tests",
    to: "/vision-tests/",
  },
  {
    label: "Library",
    to: "/blog/",
  },
  {
    label: "About",
    to: "/about-hubble/",
  },
]

export const eyeglasses = {
  linksList: [
    {
      label: "Shop All Eyeglasses",
      to: "/glasses/",
    },
    {
      label: `Women's Glasses`,
      to: "/glasses/womens-glasses/",
    },
    {
      label: `Men's Glasses`,
      to: "/glasses/mens-glasses/",
    },
    {
      label: `Kid's Glasses`,
      to: "/glasses/kids-glasses/",
    },
    {
      label: "Unisex Glasses",
      to: "/glasses/unisex-glasses/",
    },
    {
      label: "Shop by Frame Shape",
      to: "/glasses/shop-glasses-by-frame-shape/",
      subLinks: [
        {
          name: "Cat Eye",
          link: "/glasses/shop-glasses-by-frame-shape/cateye-framed-glasses/",
        },
        {
          name: "Rectangle",
          link: "/glasses/shop-glasses-by-frame-shape/rectangle-framed-glasses/",
        },
        {
          name: "Round",
          link: "/glasses/shop-glasses-by-frame-shape/round-framed-glasses/",
        },
        {
          name: "Square",
          link: "/glasses/shop-glasses-by-frame-shape/square-framed-glasses/",
        },
      ],
    },
    {
      label: "Shop by Face Shape",
      to: "/glasses/shop-glasses-by-face-shape/",
      subLinks: [
        {
          name: "Diamond",
          link: "/glasses/shop-glasses-by-face-shape/glasses-diamond-face-shape/",
        },
        {
          name: "Heart",
          link: "/glasses/shop-glasses-by-face-shape/glasses-heart-face-shape/",
        },
        {
          name: "Oval",
          link: "/glasses/shop-glasses-by-face-shape/glasses-oval-face-shape/",
        },
        {
          name: "Round",
          link: "/glasses/shop-glasses-by-face-shape/glasses-round-face-shape/",
        },        {
          name: "Square",
          link: "/glasses/shop-glasses-by-face-shape/glasses-square-face-shape/",
        },
      ],
    },
  ],
  featured: [
    {
      image: "Header/Hubble-Nav-Single-Image-Glasses-Titan.jpg",
      title: "Titan",
      price: "From $49.99",
      link: "/glasses/titan/"
    },
  ],
}


export const contactlenses = {
  linksList: [
    [{
      label: "Shop All Contact Lenses",
      to: "/contact-lenses/hubble/",
    },
    {
      label: `SkyHy by Hubble Contact Lenses`,
      description: "Breathable Silicone Hydrogel",
      to: "/contact-lenses/skyhy-by-hubble-lenses/",
    },
    {
      label: `Hydro by Hubble Contact Lenses`,
      description: "Comfortable and Naturally Hydrating",
      to: "/contact-lenses/hydro-by-hubble-lenses/",
    },
    {
      label: `Classic by Hubble Contact Lenses`,
      description: "Classic, Affordable Dailies",
      to: "/contact-lenses/classic-by-hubble-lenses",
    }]
  ],
  featured: [
    {
      image: "Header/Contacts-Category-Nav.jpg",
      title: "Our Contact Lenses",
      action: "Shop Now",
      link: "/contact-lenses/hubble/"
    },
  ],
}

export const contactlensesv2 = {
  linksList: [
    [
      {
        label: "Shop Hubble Contact Lenses",
        to: "/contact-lenses/hubble/",
      },
      {
        label: `Hubble Classic Daily Contact Lenses`,
        description: "Our Original, Affordable Dailies",
        to: "/contact-lenses/classic-by-hubble-lenses",
      },
      {
        label: `Hydro by Hubble Daily Contact Lenses`,
        description: "Comfortable and Naturally Hydrating",
        to: "/contact-lenses/hydro-by-hubble-lenses/",
      },
      {
        label: `SkyHy by Hubble Daily Contact Lenses`,
        description: "Breathable Silicone Hydrogel",
        to: "/contact-lenses/skyhy-by-hubble-lenses/",
      },
      {
        label: `SkyHy by Hubble Monthly Contact Lenses`,
        description: "Premium Technology for Less",
        to: "/contact-lenses/skyhy-monthly-by-hubble-lenses/",
      },
      {
        label: "All Hubble Contact Lenses",
        to: "/contact-lenses/hubble/",
        underline: true
      },
    ],
    [
      {
        label: "Shop All Contact Lenses",
        to: "/contact-lenses/",
      },
      {
        label: `Popular Contact Lenses`,
        to: "/contact-lenses/",
      },
      {
        label: `Contacts for Astigmatism`,
        to: "/contact-lenses/torics/",
      },
      {
        label: `All Contact Lenses`,
        to: "/contact-lenses/",
        underline: true
      }
    ],
    [
      {
        label: "Shop by Brand",
        to: null,
      },
      {
        label: `Hubble`,
        to: "/contact-lenses/hubble/",
      },
      {
        label: `SkyHy by Hubble`,
        to: "/contact-lenses/skyhy/",
      },
      {
        label: `Acuvue 1-Day Moist`,
        to: "/contact-lenses/acuvue-1-day/",
      },
      {
        label: `Acuvue Oasys`,
        to: "/contact-lenses/acuvue-oasys/",
      },
      {
        label: `Acuvue Vita`,
        to: "/contact-lenses/acuvue-vita/",
      },
      {
        label: `Bausch + Lomb Ultra`,
        to: "/contact-lenses/bausch-lomb-ultra/",
      },
      {
        label: `Biotrue`,
        to: "/contact-lenses/biotrue/",
      },
      {
        label: `All Brands`,
        to: "/contact-lenses/",
        underline: true
      },
    ],
    [
      {
        label: "Shop by Lens Type",
        to: null,
      },
      {
        label: `Daily Contact Lenses`,
        to: "/contact-lenses/daily/",
      },
      {
        label: `Weekly Contact Lenses`,
        to: "/contact-lenses/weekly/",
      },
      {
        label: `Monthly Contact Lenses`,
        to: "/contact-lenses/monthly",
      }
    ]
  ],
}

export const sunglasses = {
  linksList: [
    {
      label: "Shop All Sunglasses",
      to: "/sunglasses/",
    },
    {
      label: `Women's Sunglasses`,
      to: "/sunglasses/womens-sunglasses/",
    },
    {
      label: `Men's Sunglasses`,
      to: "/sunglasses/mens-sunglasses/",
    },
    {
      label: `Unisex Sunglasses`,
      to: "/sunglasses/unisex-sunglasses/",
    },
    {
      label: "Shop by Frame Shape",
      to: "/sunglasses/shop-sunglasses-by-frame-shape/",
      subLinks: [
        {
          name: "Cat Eye",
          link: "/sunglasses/shop-sunglasses-by-frame-shape/cateye-framed-sunglasses/",
        },
        {
          name: "Rectangle",
          link: "/sunglasses/shop-sunglasses-by-frame-shape/rectangle-framed-sunglasses/",
        },
        {
          name: "Round",
          link: "/sunglasses/shop-sunglasses-by-frame-shape/round-framed-sunglasses/",
        },
        {
          name: "Square",
          link: "/sunglasses/shop-sunglasses-by-frame-shape/square-framed-sunglasses/",
        },
      ],
    },
    {
      label: "Shop by Face Shape",
      to: "/sunglasses/shop-sunglasses-by-face-shape/",
      subLinks: [
        {
          name: "Diamond",
          link: "/sunglasses/shop-sunglasses-by-face-shape/sunglasses-diamond-face-shape/",
        },
        {
          name: "Heart",
          link: "/sunglasses/shop-sunglasses-by-face-shape/sunglasses-heart-face-shape/",
        },
        {
          name: "Oval",
          link: "/sunglasses/shop-sunglasses-by-face-shape/sunglasses-oval-face-shape/",
        },
        {
          name: "Round",
          link: "/sunglasses/shop-sunglasses-by-face-shape/sunglasses-round-face-shape/",
        },
      ],
    },
  ],
  featured: [
    {
      image: "Header/Hubble-Nav-Dual-Image-Sunglasses-Cassini.jpg",
      title: "Cassini",
      price: "From $59.99",
      link: '/sunglasses/cassini/'
    },
    {
      image: "Header/Hubble-Nav-Dual-Image-Sunglasses-Luna.jpg",
      title: "Luna",
      price: "From $59.99",
      link: '/sunglasses/luna/'
    },
  ],
}



export const about = {
  linksList: [
    {
      label: `Our Contacts`,
      to: "/about-hubble#our-contacts",
    },
    {
      label: `Our Glasses`,
      to: "/about-hubble#our-glasses",
    },
    {
      label: "Reviews",
      to: "/#reviews",
    },
    {
      label: `FAQs`,
      to: "/faq/",
    }
  ],
}
