import React, { useEffect, useState, useContext } from "react"
import { Link } from "gatsby"
import { useCookies } from "react-cookie"

import "./style.scss"
import { useWindowScroll,useWindowWidth } from "../../services/hooks"
import SideMenu from "../SideMenu"
import CartContext from "../../context/cartContext"
import { mainLinksList, eyeglasses, contactlenses, contactlensesv2, sunglasses , about} from "./mockedData"
import SubMenu from "../SubMenu"
import YourCart from "../YourCart"
import HeaderLogo from "../../images/assets/Header/Logo.svg"
import UserIcon from "../../images/assets/Header/User.svg"
import BagIcon from "../../images/assets/Header/Bag.svg"
import SearchIcon from "../../images/assets/Header/Search.svg"
import BurgerMenuIcon from "../../images/assets/Header/BurgerMenu.svg"
import { StaticImage } from "gatsby-plugin-image"
// import CanadaFlag from "../../images/assets/Header/CA-flag-round.png"
// import USAFlag from "../../images/assets/Header/US-flag-round.png"


export const tabs = {
  DEFAULT: "",
  CONTACTLENSES: "Contact Lenses",
  EYEGLASSES: "Eyeglasses",
  SUNGLASSES: "Sunglasses",
  ABOUT: "About",
}

const NewHeader = ({ isLogoHeader, isBanner }) => {
  const { headerColor, cartDisplay, setCartDisplay } = useContext(CartContext)
  const [isMenuOpened, setIsMenuOpened] = useState(false)
  const [activeTab, setActiveTab] = useState(tabs.DEFAULT)
  const [linkColor, setLinkColor] = useState("black")
  let slideOutDelay

  const windowScroll = useWindowScroll()

  const staticColors = {
    "/": "purple",
    "/vision-tests": "green",
    "/glasses": "orange",
    "/contact-lenses": "purple",
    "/search": "blue",
    "/eye-care-accessories": "blue",
    "/accessory": "blue",
  }

  useEffect(() => {
    if (typeof window !== "undefined") {
      const path =
        window.location.pathname.length > 1 &&
        window.location.pathname[window.location.pathname.length - 1] === "/" ?
          window.location.pathname.substring(0, window.location.pathname.length - 1)
          :
          window.location.pathname
      const currentLinkColor =
        staticColors?.[path] || headerColor
      currentLinkColor && setLinkColor(currentLinkColor)
    }

  }, [headerColor])

  const onHide = () => {
     slideOutDelay = setTimeout(() => {
      setIsMenuOpened(false)
      setActiveTab(tabs.DEFAULT)
    }, 300)
  }

  const handleTabMouseOver = tab => {
    clearTimeout(slideOutDelay);

    setIsMenuOpened(true)
    setActiveTab(tab)
      if (document.querySelector(".sub-menu")) document.querySelector(".sub-menu").setAttribute("style", "transform: translate(0, 0);")
    }

  const handleTabMouseOut = tab => {
    clearTimeout(slideOutDelay);

    if (document.querySelector(".sub-menu")) document.querySelector(".sub-menu").setAttribute("style", "transform: translate(0, -100%);")

    slideOutDelay = setTimeout(() => {
      if (activeTab && activeTab !== tab) return setActiveTab(tab)
       setIsMenuOpened(false)
       setActiveTab(tabs.DEFAULT)
    }, 250)
  }

  return (
    <div className="new-header-container" data-scroll={windowScroll} data-type={isBanner ? "banner" : ""} >
      {isLogoHeader ? (
      <div className="new-navbar doctor-portal" data-color={"purple"}>
        <Link className="logo" to='/' aria-label="Logo">
          <HeaderLogo alt="Logo"/>
        </Link>
      </div>) : (
      <>
      <DesktopHeader
        activeTab={activeTab}
        setActiveTab={setActiveTab}
        setIsMenuOpened={setIsMenuOpened}
        linkColor={linkColor}
        onMouseEnter={handleTabMouseOver}
        onMouseLeave={handleTabMouseOut}
      />
      <MobileHeader
        setIsMenuOpened={setIsMenuOpened}
        isMenuOpened={isMenuOpened}
        linkColor={linkColor}
      />
      <SideMenu
        data={{ mainLinksList, contactlenses, contactlensesv2, eyeglasses, sunglasses, about }}
        show={isMenuOpened}
        onHide={onHide}
      />
      <SubMenu
        data={{ contactlenses, contactlensesv2, eyeglasses, sunglasses, about}}
        show={isMenuOpened}
        setIsMenuOpened={setIsMenuOpened}
        onHide={onHide}
        activeTab={activeTab}
        setActiveTab={setActiveTab}
      />
      <YourCart show={cartDisplay} setShow={setCartDisplay} />
      </>)}
    </div>
  )
}

const DesktopHeader = ({
  isMenuOpened,
  linkColor,
  onMouseLeave,
  onMouseEnter,
}) => {
  const findTab = label => Object.values(tabs).find(tab => tab === label)

  return (
    <div className="new-navbar" data-color={linkColor}>
      <Link className="logo" to="/">
        <HeaderLogo />
      </Link>
      <div className="links-container">
        {React.Children.toArray(
          mainLinksList.map(({ label, to }) => {
            const tab = findTab(label)
            if (!tab && (tab !== "Sunglasses" || tab !== "Eyeglasses" || tab !== "About" || tab !== "Contact Lenses"))
              return (
                <a
                  className="navbar-link"
                  href={to}
                  onMouseEnter={() => onMouseLeave(tab)}
                >
                  {label}
                </a>
              )

            return (
              <a
                className="navbar-link"
                href={to}
                onMouseEnter={() => onMouseEnter(tab)}
              >
                {label}
              </a>
            )
          })
        )}
      </div>
      <ControlContainer isMenuOpened={isMenuOpened} />
    </div>
  )
}

const MobileHeader = ({ setIsMenuOpened, isMenuOpened, linkColor }) => (
  <div className="new-navbar mobile" data-color={linkColor}>
    <div className="mobile-button-container">
      <button onClick={() => setIsMenuOpened(true)} aria-label="Open Menu">
        <BurgerMenuIcon alt="Menu" />
      </button>
      <Link className="logo" to="/" aria-label="Logo">
        <HeaderLogo alt="Logo" />
      </Link>
    </div>
    <ControlContainer isMenuOpened={isMenuOpened} />
  </div>
)

const ControlContainer = ({ isMenuOpened }) => {
  const { cartItems, setCartDisplay } = useContext(CartContext)
  const [cookies, setCookie, removeCookie] = useCookies(['user-jwt', "gclid", "utm_source", "utm_medium", "utm_content", "utm_campaign"]);
  const [countryToggle, setCountryToggle] = useState(false)

  const userToken = cookies["user-jwt"]

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const search = window.location.search;
      const params = new URLSearchParams(search);
      const gclid = params.get("gclid") || params.get("clickid");
      const utm_source = params.get('utm_source');
      const utm_medium = params.get('utm_medium');
      const utm_content = params.get('utm_content');
      const utm_campaign = params.get('utm_campaign');

      if (gclid != null){
        setCookie("gclid", gclid, {
          maxAge: 1209600,
          path: "/",
        })
      }
      if (utm_source != null){
        setCookie("utm_source", utm_source, {
          maxAge: 1209600,
          path: "/",
        })
      }
      if (utm_medium != null){
        setCookie("utm_medium", utm_medium, {
          maxAge: 1209600,
          path: "/",
        })
      }
      if (utm_content != null){
        setCookie("utm_content", utm_content, {
          maxAge: 1209600,
          path: "/",
        })
      }
      if (utm_campaign != null){
        setCookie("utm_campaign", utm_campaign, {
          maxAge: 1209600,
          path: "/",
        })
      }


    }
  },[])

  return (
    <div className="control-container">
      <Link to={userToken ? "/customer-portal" : "/login"}>
        <button aria-label="Login" className="border-0 bg-transparent p-0">
          <UserIcon alt="Login" />
        </button>
      </Link>
      <button  aria-label="Cart" className="cart-icon-image border-0 bg-transparent p-0" onClick={() => setCartDisplay(true)}>
        <BagIcon alt="Cart" />
        {!!cartItems.length && !isMenuOpened && (
          <span className="circle-cart">{cartItems.length}</span>
        )}
      </button>
      <a href="/search">
        <button aria-label="Search" className="border-0 bg-transparent p-0">
          <SearchIcon alt="Search" />
        </button>
      </a>
      <div className="flag-toggle" onClick={() => setCountryToggle(!countryToggle)}>
          <StaticImage
            className="flag-image"
            src="../../images/assets/Header/US-flag-round.png"
            alt="American Flag"
          />
      </div>
      {countryToggle &&
        <div className="canada-dropdown">
          <a href="https://intake.hubblecontacts.com/ca-intake?">
            <StaticImage
                className="flag-image"
                src="../../images/assets/Header/CA-flag-round.png"
                alt="Canadian Flag"
              />
          </a>
        </div>
      }
    </div>
  )
}

export default NewHeader
