import React, { useState, useEffect, useRef } from "react"
import { Link, navigate } from "gatsby"

import "./style.scss"
import HeaderLogo from "../../images/assets/Header/Logo.svg"
import BackIcon from "../../images/assets/Header/Back.svg"
import ForwardIcon from "../../images/assets/Header/Forward.svg"
import CloseIcon from "../../images/assets/Header/Close.svg"
import WebpImage from "../WebpImage"

const tabs = {
  MAIN: "Main",
  CONTACTLENSES: "Contact Lenses",
  EYEGLASSES: "Eyeglasses",
  SUNGLASSES: "Sunglasses",
  ABOUT: "About",
}

const SideMenu = ({ show, onHide, data }) => {
  const { mainLinksList, eyeglasses, contactlenses, contactlensesv2, sunglasses, about } = data
  const [currentTab, setCurrentTab] = useState(tabs.MAIN)
  const [activeSubtab, setActiveSubtab] = useState("")

  const overlayRef = useRef()

  const handleClose = () => {
    if (document.querySelector(".side-menu")) document.querySelector(".side-menu").removeAttribute("style")
    setTimeout(() => {
        onHide()
      }, 300)
  }

  const onBack = () => {
    setActiveSubtab('')
    setTimeout(() => {
      setCurrentTab(tabs.MAIN)
    }, 500)
  }

  const checkIfClickedOutside = e => {
    show &&
      overlayRef.current &&
      overlayRef.current.isEqualNode(e.target) &&
      handleClose()
  }

  useEffect(() => {
    if (show) {
      setTimeout(() => {
        if (document.querySelector(".side-menu")) document.querySelector(".side-menu").setAttribute("style", "transform: translate(0, 0)")
      }, 100)
      document.body.setAttribute("style", "overflow: hidden;")
      document.addEventListener("mousedown", checkIfClickedOutside)
    }

    return () => {
        document.body.removeAttribute("style")
        document.removeEventListener("mousedown", checkIfClickedOutside)
        setCurrentTab(tabs.MAIN)
    }
  }, [show])

  if (!show) return null

  return (
    <div className="side-menu-overlay" ref={overlayRef}>
      <div className="header-model">
        <div className="button-container">
          {currentTab === tabs.MAIN ? (
            <button aria-label="Close" onClick={handleClose}>
              <CloseIcon alt="Close" />
            </button>
          ) : (
            <button arial-label="Back" onClick={onBack}>
              <BackIcon alt="Back" />
            </button>
          )}
        </div>
        <div className="logo-container">
          {currentTab === tabs.MAIN ? (
            <HeaderLogo />
          ) : (
            <h6 className="logo-heading">{currentTab}</h6>
          )}
        </div>
      </div>
      <div className="side-menu">
        {currentTab === tabs.MAIN && (
          <MainTab
            setCurrentTab={setCurrentTab}
            mainLinksList={mainLinksList}
            handleClick={onHide}
            setActiveSubtab={setActiveSubtab}
          />
        )}
          <ContactsTab label={"contactlenses"} tab={contactlensesv2} handleClick={onHide} currentTab={currentTab.toLowerCase().replace(' ', '')} activeSubtab={activeSubtab} />
          <GlassesTab label={"eyeglasses"} tab={eyeglasses} handleClick={onHide} currentTab={currentTab.toLowerCase().replace(' ', '')} activeSubtab={activeSubtab} />
          <GlassesTab label={"sunglasses"} tab={sunglasses} handleClick={onHide} currentTab={currentTab.toLowerCase().replace(' ', '')} activeSubtab={activeSubtab} />
          <GlassesTab label={"about"} tab={about} handleClick={onHide} currentTab={currentTab.toLowerCase()} activeSubtab={activeSubtab} />
      </div>
    </div>
  )
}

const MainTab = ({ setCurrentTab, mainLinksList, handleClick, setActiveSubtab }) => {
  const findTab = label => Object.values(tabs).find(tab => tab === label)

  return (
    <ul className="mobile-links-container">
      {mainLinksList.map(({ label, to }, index) => {
        const tab = findTab(label)

        return (
          <MobileLink
            label={label}
            to={to}
            onClick={tab ? () => {setCurrentTab(tab)
            setTimeout(() => {
              setActiveSubtab(label.toLowerCase())
            }, 200)
            } : handleClick}
            isSubLinks={tab}
            key={index}
          />
        )
      })}
    </ul>
  )
}

const ContactsTab = ({label, tab, handleClick, activeSubtab, currentTab}) => {
  const { linksList } = tab;
  return (
    <>
      {
        linksList.map(lists => {
          return(
            <ul className={`mobile-links-container contacts ${currentTab === label ? "active" : ""} ${activeSubtab.replace(' ', '') === label ? "slideout" : ""}`} id={`subtab-${label}`}>
              {
                lists.map(({ label, description, to, underline }, index) => {
                  return(
                    <MobileLink
                      label={label}
                      to={to}
                      neutral
                      onClick={handleClick}
                      description={description}
                      underline={underline}
                      className={`contacts ${index == 0 && 'bold'}`}
                    />
                  )
                })
              }
            </ul>
          )
        })
      }
    </>
  )
}

const GlassesTab = ({label, tab, handleClick, activeSubtab, currentTab}) => {
  const { linksList, featured } = tab;
  return (
    <>
      <ul className={`mobile-links-container ${currentTab === label ? "active" : ""} ${activeSubtab.replace(' ', '') === label ? "slideout" : ""}`} id={`subtab-${label}`}>
        {React.Children.toArray(
          (Array.isArray(linksList[0]) ? linksList[0] : linksList).map(({ label, description, to, subLinks }) => {
            if (subLinks)
              return (
                <li className="sub-links">
                  <h6 className="sub-links-heading">{label}</h6>
                  <div className="sub-links-container">
                    {subLinks.map(({ name, link }) => (
                      <Link
                        className="mini-link"
                        to={link}
                        onClick={handleClick}
                      >
                        {name}
                      </Link>
                    ))}
                  </div>
                </li>
              )

            return (
              <MobileLink label={label} to={to} neutral onClick={handleClick} description={description} />
            )
          })
        )}
      </ul>
      {!!featured?.length && (
        <div className={`featured-products ${currentTab === label ? "active" : ""} ${activeSubtab.replace(' ', '') === label ? "slideout" : ""}`} id={`featured-${label}`}>

          {featured.map(({ image, title, price, link, action }, index) => (
            <div
              className={`product ${label}`}
              key={index}
              onClick={() => navigate(link)}
            >
              <WebpImage className={"background-image"} wrapperClassName={"background-image"} fileName={image} />
              <div className={`info-container-${label}`}>
                <div className="product-info">
                  <h6 className="title">{title}</h6>
                  <p className="price">{price}</p>
                  {action ? <p className="action">{action}</p> : ''}
                </div>
              </div>
            </div>
          ))}
        </div>
      )}
    </>
  )
}

const MobileLink = ({ label, to, onClick, neutral, isSubLinks, description, underline, className }) => {
  const [activeLink, setActiveLink] = useState(false)
  const handleClick = () => {
    onClick()
    if (isSubLinks) {
      setActiveLink(!activeLink)
    }
  }

  return (
  <li className={`mobile-link ${className || ''} ${underline && 'underline'} ${neutral ? "neutral" : ""}`.trim()} onClick={() => handleClick()}>
    <Link to={!isSubLinks && to} className="mobile-navbar-link">
      {label}
    </Link>
    {isSubLinks && <Forward onClick={onClick} />}
    <span className="description">{description}</span>
  </li>
  )
}

const Forward = ({ onClick }) => (
  <a onClick={onClick} className="forward-button">
    <ForwardIcon />
  </a>
)

export default SideMenu
