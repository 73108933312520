import { navigate } from 'gatsby';
import React from 'react';
import './style.scss';
import WebpImage from '../WebpImage';

const Banner = ({bannerClass, bannerBG, bannerText, color, buttonLink, clickable}) => {
  return (
    <div className={`${bannerClass || 'discount-banner'} ${bannerBG}`}>
      <button onClick={() => navigate(buttonLink)} disabled={!clickable}>
        {bannerText?.mobile && bannerText?.desktop
        ? <p className={`text ${color}`}>
          <span className='text-mobile'>{bannerText?.mobile}</span>
          <span className='text-desktop' >{bannerText?.desktop}</span>
          <WebpImage fileName="ArrowRightWhite.svg" alt="Arrow right" className='cta-arrow'/>
        </p>
        : <p className={`text ${color}`} dangerouslySetInnerHTML={{__html: bannerText}} />
        }
      </button>
    </div>
  )
}

export default Banner