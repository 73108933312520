/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import { ToastContainer } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import NewHeader from "./NewHeader"
import Banner from "./Banner"
import NewFooter from "./NewFooter"
import ZendeskWidget from "./ZendeskWidget"
import Overlay from "./Overlay"
import "../styles/style.scss"
import { library, config, dom } from "@fortawesome/fontawesome-svg-core"
import { fab } from "@fortawesome/free-brands-svg-icons"
import {
  faAngleUp,
  faAngleDown,
  faCaretUp,
  faCaretDown,
} from "@fortawesome/free-solid-svg-icons"
import LogRocket from "./LogRocket";

// The following import prevents a Font Awesome icon server-side rendering bug,
// where the icons flash from a very large icon down to a properly sized one:
// import "@fortawesome/fontawesome-svg-core/styles.css"
// Prevent fontawesome from adding its CSS since we did it manually above:
config.autoAddCss = false /* eslint-disable import/first */

library.add(fab, faAngleUp, faAngleDown, faCaretUp, faCaretDown)

// Take contact form as an example
// Let's customise our contact form appearance, launcher and add prefill content

const Layout = ({ children, className, isLogoHeader, isDoctorPortal, isReducedFooter }) => {
  const isDeclinedPaymentCustomer = typeof window !== "undefined" ? window?.location?.pathname.includes("/customer-portal") && localStorage.getItem('declinedPaymentCustomer') : null
  const declineCode = typeof localStorage != 'undefined' ? localStorage.getItem('declineCode') : null
  const discountPercent = declineCode?.includes('twenty') ? 20 : 40
  const [userHasScrolled, setUserHasScrolled] = useState(false)  
  if (typeof window !== "undefined") {
    window.addEventListener(
      'scroll',
      () => setUserHasScrolled(true),
      { once: true }
    )
  }

  return (
    <>
      <div id="scroll-top-position"/>
      {userHasScrolled && <style>{dom.css()}</style>}
      {isDeclinedPaymentCustomer && <Banner bannerText={`Get ${discountPercent}% off your next order`} bannerBG={"purple"} buttonLink="/customer-portal?page=accountInfo&cardModal=true" clickable/>}
      <NewHeader isLogoHeader={isLogoHeader} isDoctorPortal={isDoctorPortal} isBanner={isDeclinedPaymentCustomer} />
      <div className="main-content-wrapper">
        <main className={className}>{children}</main>

        <ToastContainer
          position="top-center"
          autoClose={5000}
          pauseOnHover={false}
        />
        <LogRocket />
        <ZendeskWidget />
      </div>
      <NewFooter isDoctorPortal={isDoctorPortal} isReducedFooter={isReducedFooter} />
      <Overlay />
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
