import React, { useEffect, useState } from 'react';

const LogRocket = () => {
  const [logRocketLoaded, setLogRocketLoaded] = useState(false)

    // logrocket script moved to async so need to check it's loaded
    const checkLogRocket = (retries = 0) => {
      if (typeof window != undefined) {
        if (typeof window?.LogRocket?.init === "function") {
          setLogRocketLoaded(true)
        } else if (retries < 40) {
          // if (retries > 0) console.log(`Retry ${retries} - logrocket not found`)
          setTimeout(() => checkLogRocket(retries+1), 250)
        }
      }
    }
    useEffect(() => {checkLogRocket()}, [])
  
    useEffect(()=> {
      if(typeof window != undefined) {
        if (logRocketLoaded) {
          // only run on prod (hubblecontacts.com)
          if (window?.location?.host?.includes('hubblecontacts.com')) {
            window.LogRocket.init('ryrxm0/hubble-guhqq');
            const customerData = localStorage?.customer_data
            const customer = customerData ? JSON.parse(customerData) : null
            if (customer) {
              window.LogRocket.identify(customer.id, {
                name: `${customer.first_name} ${customer.last_name}`,
                email: customer.email,
              });
            }
          }
        }
      }
    }, [logRocketLoaded])

  return (
    <div id="log-rocket-loaded"></div>
  )
}

export default LogRocket