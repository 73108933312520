import React, { useState, useEffect } from "react"
import { useWindowWidth } from "../../services/hooks"
import "./style.scss"
import { StaticImage } from "gatsby-plugin-image"


const ZendeskWidget = () => {
  const [widgetOpen, setWidgetOpen] = useState(false)
  const windowWidth = useWindowWidth()

  useEffect(() => {
    if(windowWidth > 578 && !!window?.zE){
      window.zE('messenger:set', 'zIndex', 123);
    }
  }, [windowWidth])

  const openZendeskWidget = () => {
    if (!widgetOpen) {
      window.zE('messenger', 'open');
    }
    else {
      window.zE('messenger', 'close');
    }
    setWidgetOpen(!widgetOpen)
  }

  return <div className={`zendesk-widget-logo ${widgetOpen ? 'hidden': ''}`} onClick={() => openZendeskWidget()}>
    <StaticImage src='../../images/zendesk-chat-widget.png' alt="zendesk-chat-logo" />
  </div>
}

export default ZendeskWidget
